import { ReactElement, ReactNode, useState } from "react";
import { PreviewTokenContext, PreviewTokenContextType } from "./preview-token-context";
import { getPreviewToken, removePreviewTokenFromUrl, storePreviewTokenOnUrl } from "../../../services/preview/preview-service";
import RouteConsts from "../../../layout/RouteConsts";

interface Props {
  children: ReactNode;
}

/*
 * This component is a provider for the preview token context. It sets the preview token on the URL if the user is on the preview page.
 */
// eslint-disable-next-line import/prefer-default-export
export const PreviewTokenProvider = (props: Props): ReactElement => {
  const { children } = props;
  const [knownToken, setKnownToken] = useState<string>();

  const handleSetTokenOnUrl = (token: string) => {
    setKnownToken(token);
    if (token) {
      storePreviewTokenOnUrl(token);
    } else {
      removePreviewTokenFromUrl();
    }
  };

  // Limit the token to only be set if we're on the preview page
  if (window.location.pathname === RouteConsts.Preview) {
    const previewToken = getPreviewToken();
    if (previewToken !== knownToken) {
      setKnownToken(previewToken);
    }
  } else if (knownToken) {
    // Clear the current token
    setKnownToken(undefined);
  }

  const context = {
    previewToken: knownToken,
    setTokenOnUrl: (token: string) => handleSetTokenOnUrl(token),
  } as PreviewTokenContextType;

  return <PreviewTokenContext.Provider value={context}>{children}</PreviewTokenContext.Provider>;
};
