/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, ReactElement, Suspense } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import HttpStatusCode from "../common/httpStatusCode";
import getProductInfo from "../common/productInfo";

import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import RouteConsts from "./RouteConsts";

import LoadingPage from "../views/LoadingPage";
import "./Layout.scss";

import { TrueLayerProviderName } from "../models/paymentProviders/trueLayer/trueLayerModels";
import { TotalProcessingProviderName } from "../models/paymentProviders/totalProcessing/total-processing-provider";
import IntentToPayServiceProvider from "../context/intentToPay/IntentToPayProvider";

const HealthPage = lazy(() => import("../views/HealthPage"));
const HomePage = lazy(() => import("../views/Home/HomePage"));
const PreviewPage = lazy(() => import("../views/preview/preview-page"));
const TestPage = lazy(() => import("../views/Test/TestPage"));
const ErrorPage = lazy(() => import("./Errors/ErrorPage"));

const RequestLinkPage = lazy(() => import("../views/RequestLink/RequestLinkPage"));
const PaymentRequestPage = lazy(() => import("../views/PaymentRequest/payment-request-page"));

const PaymentWaitingPage = lazy(() => import("../views/Payments/PaymentWaitingPage"));
const PaymentCompletePage = lazy(() => import("../views/Payments/PaymentCompletePage"));
const PaymentReservedPage = lazy(() => import("../views/Payments/PaymentReservedPage"));
const PaymentProcessingPage = lazy(() => import("../views/Payments/PaymentProcessingPage"));
const PaymentBankDebitScheduledPage = lazy(() => import("../views/Payments/PaymentBankDebitSchedulePage"));

const SessionExpiredPage = lazy(() => import("../views/SessionExpired/SessionExpiredPage"));
const BankDebitSetupStatusPage = lazy(() => import("../views/Payments/BankDebitSetupStatusPage"));

const OpayoPiCardFramePage = lazy(() => import("../views/PaymentProviders/opayo/pi/iframe/opayo-pi-card-frame-page"));
const OpayoPi3dSecureRedirectPage = lazy(
  () => import("../views/PaymentProviders/opayo/pi/iframe/opayo-pi-3d-secure-reedirect-page")
);
const OpayoPi3dSecureFramePage = lazy(() => import("../views/PaymentProviders/opayo/pi/iframe/opayo-pi-3d-secure-frame-page"));

const TrueLayerRedirectPage = lazy(() => import("../views/PaymentProviders/true-layer/true-layer-redirect-page"));
const TotalProcessingRedirectPage = lazy(
  () => import("../views/PaymentProviders/total-processing/total-processing-redirect-page")
);

const Layout = (): ReactElement => {
  const pi = getProductInfo();
  return (
    <IntentToPayServiceProvider>
      <BrowserRouter>
        <Header />
        <Container fluid>
          <div>{pi.appVersion}</div>
          <div className='flex-container'>
            <div className='d-flex flex-column my-4 main-page'>
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  <Route key={RouteConsts.Health} path={RouteConsts.Health} component={HealthPage} />
                  <Route path={RouteConsts.Home} exact component={HomePage} />
                  <Route path={RouteConsts.Preview} exact component={PreviewPage} />
                  <Route path={RouteConsts.Test} exact component={TestPage} />
                  {/* <Route path='/styles' exact component={StyleGuidePage} /> */}
                  <Route path={`${RouteConsts.RequestLink}/:requestLinkId`} component={RequestLinkPage} />
                  <Route path={RouteConsts.Session} component={PaymentRequestPage} />
                  <Route
                    path={`${RouteConsts.PaymentProcessing}/${TrueLayerProviderName}/:token`}
                    component={TrueLayerRedirectPage}
                  />
                  <Route
                    path={`${RouteConsts.PaymentProcessing}/${TotalProcessingProviderName}`}
                    component={TotalProcessingRedirectPage}
                  />
                  <Route path={`${RouteConsts.PaymentProcessing}/:providerName`} component={PaymentProcessingPage} />
                  <Route path={RouteConsts.PaymentProcessing} component={PaymentProcessingPage} />
                  <Route path={RouteConsts.PaymentWaiting} component={PaymentWaitingPage} />
                  <Route path={RouteConsts.PaymentReserved} component={PaymentReservedPage} />
                  <Route path={RouteConsts.PaymentBankDebitScheduled} component={PaymentBankDebitScheduledPage} />
                  <Route path={RouteConsts.PaymentComplete} component={PaymentCompletePage} />
                  <Route path={RouteConsts.OpayoEmbeddedCard} component={OpayoPiCardFramePage} />
                  <Route path={RouteConsts.OpayoEmbedded3dSecure} component={OpayoPi3dSecureFramePage} />
                  <Route path={RouteConsts.Opayo3dSecureRedirect} component={OpayoPi3dSecureRedirectPage} />
                  <Route path={RouteConsts.SessionExpired} component={SessionExpiredPage} />
                  <Route
                    path={`${RouteConsts.DirectDebitSetupStatus}/:providerName/:status`}
                    component={BankDebitSetupStatusPage}
                  />
                  <Route path={`${RouteConsts.Error}/:code`} component={ErrorPage} />
                  <Redirect to={`${RouteConsts.Error}/${HttpStatusCode.NotFound.toString()}`} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </Container>
        <Footer />
      </BrowserRouter>
    </IntentToPayServiceProvider>
  );
};

export default Layout;
