import CompletePayRequestProviderData from "../../paymentProcess/completePayRequestProviderData";
import CompletePayResultProviderData from "../../paymentProcess/completePayResultProviderData";
import IntentToPayRequestProviderData from "../../paymentProcess/intentToPayRequestProviderData";
import IntentToPayResultProviderData from "../../paymentProcess/intentToPayResultProviderData";
import { PaymentChargeType } from "../../paymentProcess/paymentChargeType";
import { StorePaymentMethodSettings } from "../payment-providers-common";
import { PaymentProviderSettings } from "../paymentProviderConfiguration";

export const TotalProcessingProviderName = "TotalProcessing";

export interface TotalProcessingSettings extends PaymentProviderSettings, StorePaymentMethodSettings {}

export interface TotalProcessingIntentToPayRequestProviderData extends IntentToPayRequestProviderData {
  // Card or token payment
  paymentChargeType: PaymentChargeType;

  // Id of the stored card to use if using token
  storedCardId?: string;

  // Optionally store the payment details for future use when creating a payment intent
  storePaymentMethodDetails?: boolean;
}

export interface TotalProcessingIntentToPayResultProviderData extends IntentToPayResultProviderData {
  // Manual or token payment
  paymentChargeType: PaymentChargeType;

  // Id provided by Total Processing
  id: string;

  // URL to the checkout script to use
  scriptUrl: string;

  // List of payment brands to allow
  brands: string[];
}

export interface TotalProcessingCompletePayRequestProviderData extends CompletePayRequestProviderData {
  // Manual or token payment
  paymentChargeType: PaymentChargeType;

  // Id of the payment from Total Processing if the payment type is manual
  id: string;

  // Resource path to call if the payment type is manual
  resourcePath: string;
}

export interface TotalProcessingCompletePayResultProviderData extends CompletePayResultProviderData {}

/*
 * Constructor for TotalProcessingIntentToPayRequestProviderData
 */
export const initCardPaymentIntentToPayResultProviderData = (
  storePaymentMethodDetails?: boolean
): TotalProcessingIntentToPayRequestProviderData => {
  const data: TotalProcessingIntentToPayRequestProviderData = {
    providerName: TotalProcessingProviderName,
    paymentChargeType: PaymentChargeType.Manual,
    storePaymentMethodDetails,
  };
  return data;
};

/*
 * Constructor for TotalProcessingIntentToPayRequestProviderData
 */
export const initTokenPaymentIntentToPayResultProviderData = (
  storedCardId: string
): TotalProcessingIntentToPayRequestProviderData => {
  const data: TotalProcessingIntentToPayRequestProviderData = {
    providerName: TotalProcessingProviderName,
    paymentChargeType: PaymentChargeType.Token,
    storedCardId,
  };
  return data;
};

/*
 * Constructor for TotalProcessingCompletePayRequestProviderData
 */
export const initTotalProcessingCompletePayRequestProviderData = (
  id: string,
  resourcePath: string
): TotalProcessingCompletePayRequestProviderData => {
  const data: TotalProcessingCompletePayRequestProviderData = {
    providerName: TotalProcessingProviderName,
    // TODO move this to the main complate pay request
    paymentChargeType: PaymentChargeType.Manual,
    id,
    resourcePath,
  };
  return data;
};
